import './../styles/comps/cs-link.scss';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';
import React from 'react';

function CsLink({ href, icon, name, subLinks = [] }) {
  const { pathname } = useLocation();
  const isActive = path => {
    if (pathname.includes(path)) {
      return 'active';
    }

    return '';
  };

  return (
    <>
      <div className="cs-link">
        <Link
          to={subLinks.length ? href + subLinks[0].href : href}
          className={isActive(href)}
        >
          <FontAwesomeIcon icon={icon} className="icon" /> {name}
        </Link>

        {subLinks.length ? (
          <FontAwesomeIcon
            icon={isActive(href) ? faCaretUp : faCaretDown}
            className="caret"
          />
        ) : null}
      </div>

      {subLinks.map(link => {
        return (
          <div key={link.href} className={`cs-link sublink ${isActive(href)}`}>
            <Link to={`${href}${link.href}`} className={isActive(link.href)}>
              <span></span> {link.name}
            </Link>
          </div>
        );
      })}
    </>
  );
}

export default CsLink;
