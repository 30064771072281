import '../../styles/pages/loans.scss';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  createLoanSetting,
  deleteLoanSetting,
  getLoanSettings,
  updateLoanSetting
} from './../../core/services/loan';
import { dateFormat } from './../../core/utils/dateFormat';
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  createRow,
  useMaterialReactTable
} from 'material-react-table';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import CsButton from './../../comps/CsButton';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import {
  getFormDownloadUrl,
  uploadForm
} from '../../core/services/loan-application';

const Loans = () => {
  const fileRef = useRef(null);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getData = () => {
    getLoanSettings().then(res => {
      setData(res);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleCreate = async ({ values, table }) => {
    table.setCreatingRow(null);
    setIsLoading(true);
    await createLoanSetting(values);
    getData();
  };

  const handleUpdate = async ({ row, values, table }) => {
    table.setEditingRow(null);
    setIsLoading(true);
    await updateLoanSetting({ ...values, id: row.original.id });
    getData();
  };

  const openDeleteConfirmModal = async row => {
    if (
      window.confirm(`Are you sure you want to delete ${row.original.field}?`)
    ) {
      setIsLoading(true);
      await deleteLoanSetting(row.original);
      getData();
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'field',
        header: 'Name'
      },
      {
        accessorKey: 'filename',
        header: 'File Name'
      },
      {
        accessorKey: 'created_at',
        accessorFn: row => dateFormat(row.created_at),
        header: 'Created Date',
        enableEditing: false
      },
      {
        accessorKey: 'updated_at',
        accessorFn: row => dateFormat(row.updated_at),
        header: 'Updated Date',
        enableEditing: false
      },
      {
        accessorKey: 'status',
        header: 'Status',
        enableEditing: false
      }
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowActions: true,
    enableEditing: true,
    createDisplayMode: 'modal',
    state: {
      isLoading
    },
    renderDetailPanel: ({ row }) => {
      return (
        <div style={{ display: 'flex', gap: '1rem' }}>
          <CsButton
            label="Download File"
            onClick={() => {
              if (row.original.filename) {
                getFormDownloadUrl(row.original.filename).then(res => {
                  window.open(res);
                });
              } else {
                alert('No file available');
              }
            }}
          />
          <CsButton
            label="Upload or Change File"
            onClick={() => fileRef.current.click()}
          />
          <input
            ref={fileRef}
            type="file"
            onChange={event => {
              const file = event.target.files[0];

              if (row.original.filename) {
                uploadForm({ file, filename: row.original.filename }).then(
                  res => {
                    alert('Upload success');
                  }
                );
              } else {
                alert('Please set file name before uploading file.');
              }
            }}
            hidden
          />
        </div>
      );
    },
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex' }}>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              table.setEditingRow(row);
            }}
          >
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              openDeleteConfirmModal(row);
            }}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <CsButton
        label="New"
        icon={faPlus}
        onClick={() => {
          table.setCreatingRow(
            createRow(table, {
              created_at: new Date(),
              updated_at: new Date(),
              status: 'Active'
            })
          );
        }}
      />
    ),
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">Create New Loan Product</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">Edit Loan Product</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    onCreatingRowSave: handleCreate,
    onEditingRowSave: handleUpdate
  });

  return (
    <div className="loans">
      <div className="loans__list">
        <MaterialReactTable table={table} />
      </div>
    </div>
  );
};

export default Loans;
