import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { db, storage } from '../../firebase';
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';

export const getFormDownloadUrl = async filename => {
  try {
    const storageRef = ref(storage, 'forms/' + filename);

    return await getDownloadURL(storageRef);
  } catch (error) {
    return error;
  }
};

export const uploadForm = async data => {
  try {
    const storageRef = ref(storage, 'forms/' + data.filename);

    return await uploadBytes(storageRef, data.file);
  } catch (error) {
    return error;
  }
};

export const submitLoanApplication = async data => {
  try {
    const uniqueRef = new Date().getTime();
    const filename =
      data.user.client_id + // Client ID
      '-' +
      uniqueRef + // Unique reference
      '-' +
      data.details.field + // Loan type
      '.' +
      data.file.name.split('.').pop(); // File name extension

    const storageRef = ref(storage, 'loan-applications/' + filename);

    const response = await uploadBytes(storageRef, data.file);

    if (!response.metadata.fullPath) {
      throw { message: 'Failed to upload file' };
    }

    return await addDoc(collection(db, 'loan_applications'), {
      client_name: [data.user.first_name, data.user.last_name].join(' '),
      client_id: data.user.client_id,
      loan_type: data.details.field,
      filename,
      created_at: new Date().getTime(),
      updated_at: null,
      status: 'Pending'
    });
  } catch (error) {
    return error;
  }
};

export const getLoanDownloadUrl = async filename => {
  try {
    const storageRef = ref(storage, 'loan-applications/' + filename);

    return await getDownloadURL(storageRef);
  } catch (error) {
    return error;
  }
};

export const approveLoanApplication = async id => {
  try {
    const res = await updateDoc(doc(db, 'loan_applications', id), {
      status: 'Approved',
      updated_at: new Date().getTime()
    });

    return res;
  } catch (error) {
    return error;
  }
};

export const declineLoanApplication = async id => {
  try {
    const res = await updateDoc(doc(db, 'loan_applications', id), {
      status: 'Declined',
      updated_at: new Date().getTime()
    });

    return res;
  } catch (error) {
    return error;
  }
};
