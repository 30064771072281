import './../../styles/pages/forum.scss';
import { Delete, Edit } from '@mui/icons-material';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip
} from '@mui/material';
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  createRow,
  useMaterialReactTable
} from 'material-react-table';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import CsButton from '../../comps/CsButton';
import { faImage, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import {
  createPost,
  deletePost,
  getPostDownloadUrl,
  getPostsListData,
  updatePost
} from '../../core/services/forum';
import { dateFormat } from '../../core/utils/dateFormat';
import { getUserFromSessionStorage } from '../../core/services/auth';

const AdminForum = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [title, setTitle] = useState('');
  const [details, setDetails] = useState('');

  const imageRef = useRef(null);
  const [imagePreview, setImagePreview] = useState('');

  const handleImagePreview = event => {
    const objectUrl = URL.createObjectURL(event.target.files[0]);
    setImagePreview(objectUrl);
  };

  const getData = () => {
    getPostsListData().then(res => {
      setData(res.posts);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleCreate = async ({ table }) => {
    table.setCreatingRow(null);
    setIsLoading(true);
    const res = await createPost({
      title,
      details,
      file: imageRef.current.files[0]
    });
    if (res.error) {
      alert(res.message);
    }
    getData();
  };
  const handleUpdate = async ({ row, table }) => {
    table.setEditingRow(null);
    setIsLoading(true);

    await updatePost({
      title,
      details,
      id: row.original.id,
      filename: row.original.filename,
      file: imageRef.current.files[0]
    });
    getData();
  };

  const openDeleteConfirmModal = async row => {
    if (window.confirm(`Are you sure you want to delete this post?`)) {
      setIsLoading(true);
      await deletePost(row.original);
      getData();
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'posted_at',
        accessorFn: row => dateFormat(row.posted_at),
        header: 'Posted On',
        size: 50,
        enableEditing: false
      },
      {
        accessorKey: 'poster_name',
        header: 'Author',
        size: 50,
        enableEditing: false
      },
      {
        accessorKey: 'title',
        header: 'Title',
        size: 200
      },
      {
        accessorKey: 'details',
        header: 'Details',
        size: 1000
      }
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowActions: true,
    enableEditing: true,
    createDisplayMode: 'modal',
    state: {
      isLoading
    },
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex' }}>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              if (row.original.filename) {
                getPostDownloadUrl(row.original.filename).then(url => {
                  setImagePreview(url);
                });
              }

              setTitle(row.original.title);
              setDetails(row.original.details);
              table.setEditingRow(row);
            }}
          >
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              openDeleteConfirmModal(row);
            }}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <CsButton
        label="Post"
        icon={faPaperPlane}
        onClick={() => {
          setTitle('');
          setDetails('');
          table.setCreatingRow(createRow(table));
        }}
      />
    ),
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => {
      return (
        <>
          <DialogTitle variant="h6">Post Something</DialogTitle>
          <DialogContent
            sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
          >
            <input
              ref={imageRef}
              type="file"
              accept="image/*"
              onChange={handleImagePreview}
              hidden
            />

            {imagePreview && (
              <div
                style={{
                  textAlign: 'center',
                  width: '100%',
                  height: 300,
                  borderRadius: 5,
                  backgroundColor: 'black'
                }}
              >
                <img src={imagePreview} alt="" height="100%" />
              </div>
            )}

            <CsButton
              label="Attach Image"
              onClick={() => {
                imageRef.current.click();
              }}
              icon={faImage}
            />

            <TextField
              label="Title"
              variant="standard"
              value={title}
              onChange={event => {
                setTitle(event.target.value);
              }}
            />

            <TextField
              label="Put your post details here..."
              variant="outlined"
              value={details}
              onChange={event => {
                setDetails(event.target.value);
              }}
              minRows={7}
              multiline
            />
          </DialogContent>
          <DialogActions>
            <MRT_EditActionButtons variant="text" table={table} row={row} />
          </DialogActions>
        </>
      );
    },
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => {
      return (
        <>
          <DialogTitle variant="h6">Edit Post</DialogTitle>
          <DialogContent
            sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
          >
            <input
              ref={imageRef}
              type="file"
              accept="image/*"
              onChange={handleImagePreview}
              hidden
            />

            {imagePreview && (
              <div
                style={{
                  textAlign: 'center',
                  width: '100%',
                  height: 300,
                  borderRadius: 5,
                  backgroundColor: 'black'
                }}
              >
                <img src={imagePreview} alt="" height="100%" />
              </div>
            )}

            <CsButton
              label="Attach Image"
              onClick={() => {
                imageRef.current.click();
              }}
              icon={faImage}
            />

            <TextField
              label="Title"
              variant="standard"
              value={title}
              onChange={event => {
                setTitle(event.target.value);
              }}
            />

            <TextField
              label="Put your post details here..."
              variant="outlined"
              value={details}
              onChange={event => {
                setDetails(event.target.value);
              }}
              minRows={7}
              multiline
            />
          </DialogContent>
          <DialogActions>
            <MRT_EditActionButtons variant="text" table={table} row={row} />
          </DialogActions>
        </>
      );
    },
    onCreatingRowSave: handleCreate,
    onEditingRowSave: handleUpdate,
    onCreatingRowCancel: () => {
      setImagePreview('');
    },
    onEditingRowCancel: () => {
      setImagePreview('');
    }
  });

  return (
    <div className="admin-forum">
      <div className="admin-forum__list">
        <MaterialReactTable table={table} />
      </div>
    </div>
  );
};

const MemberForum = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getData = () => {
    getPostsListData().then(res => {
      setData(res.posts);
      setIsLoading(false);
    });
  };

  const getDownloadUrl = async filename => {
    return await getPostDownloadUrl(filename);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="member-forum">
      <div className="member-forum__list">
        {data.map(post => (
          <PostCard post={post} />
        ))}
      </div>
    </div>
  );
};

const PostCard = ({ post }) => {
  const [image, setImage] = useState('');

  useEffect(() => {
    getPostDownloadUrl(post.filename).then(url => {
      setImage(url);
    });
  }, []);

  return (
    <div key={post.id} className="member-forum__item">
      <div className="name">{post.poster_name}</div>
      <div className="date">{dateFormat(post.posted_at)}</div>

      {post.filename && (
        <div className="image">
          <img src={image} alt="" height="100%" />
        </div>
      )}

      <div className="title">{post.title}</div>
      <div className="details">{post.details}</div>
    </div>
  );
};

const Forum = () => {
  const user = getUserFromSessionStorage();
  const [isAdmin] = useState(user?.role === 'admin');

  return (
    <div className="forum">{isAdmin ? <AdminForum /> : <MemberForum />}</div>
  );
};

export default Forum;
