import {
  collection,
  doc,
  endAt,
  getDocs,
  limit,
  or,
  orderBy,
  query,
  startAfter,
  startAt,
  updateDoc,
  where
} from 'firebase/firestore';
import { db } from '../../firebase';

export const getMembersListData = async ({
  action,
  batchLimit,
  start,
  search
}) => {
  try {
    let members = null;

    if (action === 'more') {
      members = await getDocs(
        query(collection(db, 'clients'), startAfter(start), limit(batchLimit))
      );
    } else if (action === 'search') {
      members = await getDocs(
        query(
          collection(db, 'clients'),
          or(
            where('client_id', '==', search),
            where('first_name', '==', search),
            where('middle_name', '==', search),
            where('last_name', '==', search)
          )
        )
      );
    } else {
      members = await getDocs(
        query(collection(db, 'clients'), limit(batchLimit))
      );
    }

    const last = members.docs[members.docs.length - 1];

    let membersData = [];

    members.forEach(doc => {
      membersData = [...membersData, { ...doc.data(), id: doc.id }];
    });

    return { members: membersData, last };
  } catch (error) {
    return error;
  }
};

export const updateMemberDetails = async data => {
  try {
    const res = await updateDoc(doc(db, 'clients', data.id), {
      email: data.email,
      staff_id: data.staff_id,
      client_type: data.client_type,
      first_name: data.first_name,
      middle_name: data.middle_name,
      last_name: data.last_name,
      contact: data.contact,
      sex: data.sex,
      dob: data.dob,
      status: data.status,
      updated_at: new Date().getTime()
    });

    return res;
  } catch (error) {
    return error;
  }
};

export const generateOnlineLogs = async ({ start, end }) => {
  try {
    const res = await getDocs(
      query(
        collection(db, 'user_online_logs'),
        orderBy('executed_at'),
        startAt(new Date(start + ' 00:00:00').getTime()),
        endAt(new Date(end + ' 23:59:59').getTime())
      )
    );

    let data = [];

    res.forEach(doc => {
      data = [...data, { ...doc.data(), id: doc.id }];
    });

    return data;
  } catch (error) {
    return error;
  }
};
