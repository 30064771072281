import './../../styles/pages/loan-application.scss';
import CsSelect from './../../comps/CsSelect';
import CsButton from './../../comps/CsButton';
import React, { useEffect, useRef, useState } from 'react';
import {
  getFormDownloadUrl,
  submitLoanApplication
} from '../../core/services/loan-application';
import { getUserFromSessionStorage } from '../../core/services/auth';
import { getLoanSettings } from './../../core/services/loan';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHammer,
  faScrewdriverWrench
} from '@fortawesome/free-solid-svg-icons';

const LoanApplication = () => {
  const [data, setData] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const fileInput = useRef(null);

  useEffect(() => {
    getLoanSettings().then(res => {
      const sorted = res.sort((a, b) => a.field.localeCompare(b.field));

      setData(sorted);
    });
  }, []);

  const handleOnSelect = option => {
    setSelectedProduct(option);
  };

  return (
    <div className="loan-application">
      <h1>
        <FontAwesomeIcon icon={faScrewdriverWrench} /> Under construction
      </h1>
      {/* <div className="loan-application__section section-1">
        <CsSelect
          keyRef="field"
          displayRef="field"
          label="Select Loan"
          options={data}
          onSelect={handleOnSelect}
        />
      </div>

      {selectedProduct ? (
        <div className="loan-application__section section-2">
          <div className="instructions">
            <p>
              Instructions for <b>{selectedProduct.field}</b> application.
            </p>
            <ul>
              <li>1. Download and print the loan application form below.</li>
              <li>2. Fill up the form.</li>
              <li>
                3.
                {selectedProduct.value === 'real_property_loan'
                  ? ' Scan the form and submit the necessary documentary attachments below.'
                  : ' Scan the form and submit via “Submit Loan Application”.'}
              </li>
            </ul>
          </div>
          <div className="col">
            <CsButton
              label="Download Form"
              onClick={() => {
                if (selectedProduct?.filename) {
                  getFormDownloadUrl(selectedProduct.filename).then(url => {
                    window.open(url);
                  });
                } else {
                  alert('No file available.');
                }
              }}
            />
            {selectedProduct.value !== 'real_property_loan' ? (
              <div>
                <input
                  ref={fileInput}
                  type="file"
                  onChange={event => {
                    const file = event.target.files[0];

                    if (file) {
                      const user = getUserFromSessionStorage();
                      submitLoanApplication({
                        user,
                        file,
                        details: selectedProduct
                      }).then(res => {
                        alert('Loan application submitted!');
                      });
                    }
                  }}
                  hidden
                />
                <CsButton
                  label="Submit Loan Application"
                  mode="success"
                  onClick={() => {
                    fileInput.current.click();
                  }}
                />
                <p>Submit file in PDF/JPG/PNG formats</p>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      {selectedProduct?.value === 'real_property_loan' ? (
        <div className="loan-application__section section-3">
          <p>Upload documents here:</p>

          <div className="group">
            <CsButton label="Upload" className="button" />
            <p>Application form with Promissory Note</p>
          </div>

          <div className="group">
            <CsButton label="Upload" className="button" />
            <p>Marriage Certificate, if Married</p>
          </div>

          <div className="group">
            <CsButton label="Upload" className="button" />
            <p>
              Electronic copy of TCT/OCT/CCT under the name of thhe borrower/s,
              if applicable (all pages including blank pages)
            </p>
          </div>

          <div className="group">
            <CsButton label="Upload" className="button" />
            <p>
              Updated copy of Tax Declaration (House and Lot_ in the name of the
              borrower/s, if applicable
            </p>
          </div>

          <div className="group">
            <CsButton label="Upload" className="button" />
            <p>
              Real Estate Tax Receipt/Tax Clearance (Photocopy) in the name of
              the borrower/s for the current year
            </p>
          </div>

          <div className="group">
            <CsButton label="Upload" className="button" />
            <p>
              Loan and Mortgage Agreement duly regisred with the Registry of
              Deeds with Orignial RD stamp
            </p>
          </div>

          <div className="group">
            <CsButton label="Upload" className="button" />
            <p>
              Deed of Absolute Sale or Deed of Contract to Buy/Sell or option to
              Buy/Sell the real estate property
            </p>
          </div>

          <div className="group">
            <CsButton label="Upload" className="button" />
            <p>
              Lot Plan with Location/Vicinity Map certified by a licensed
              Geodetic Engineer
            </p>
          </div>

          <div className="group">
            <CsButton label="Upload" className="button" />
            <p>Photos of the real estate property</p>
          </div>

          <div className="group">
            <CsButton label="Upload" className="button" />
            <p>
              Deed of Undertaking to cause the transfer of the TCT/OCT/CCT to
              the borrowing member and the registration of the REM with the
              Registry of Deeds
            </p>
          </div>

          <p>For refinancing of an existing real estate or home loan:</p>

          <div className="group">
            <CsButton label="Upload" className="button" />
            <p>(i) current statement of Account</p>
          </div>

          <div className="group">
            <CsButton label="Upload" className="button" />
            <p>
              (ii) Official Receipt for amortization payments made or
              Passbook/Bank Statement where amortization payment is debited (at
              least 6 months)
            </p>
          </div>

          <p>For construction, renovation and/or improvements:</p>

          <div className="group">
            <CsButton label="Upload" className="button" />
            <p>
              (i) Bills of Materials/Specifications/Cost Estimates duly signed
              by a licensed Civil Engineer or Architect
            </p>
          </div>

          <div className="group">
            <CsButton label="Upload" className="button" />
            <p>(ii) Scope of Work (for renovation)</p>
          </div>

          <div className="group">
            <CsButton label="Upload" className="button" />
            <p>
              (iii) Building Plan (complete set) duly signed by a licensed
              Engineer or Architect
            </p>
          </div>

          <div className="group">
            <CsButton label="Upload" className="button" />
            <p>
              (iv) Building Permit and other relevant permits as may be required
            </p>
          </div>
        </div>
      ) : null} */}
    </div>
  );
};

export default LoanApplication;
