import React from 'react';
import { Route, Routes } from 'react-router-dom';
import UploadSOAs from './UploadSOAs';
import DeleteSOAs from './DeleteSOAs';

const SOAs = () => {
  return (
    <Routes>
      <Route path="/upload" element={<UploadSOAs />} />
      <Route path="/delete" element={<DeleteSOAs />} />
    </Routes>
  );
};

export default SOAs;
