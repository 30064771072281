import './../../styles/pages/loan-details-calc.scss';
import CsSelect from '../../comps/CsSelect';
import CsInput from '../../comps/CsInput';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import { getCalculatorSettings } from './../../core/services/calculator';

const LoanDetailsCalc = () => {
  const [data, setData] = useState([]);
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [amount, setAmount] = useState();
  const [term, setTerm] = useState();
  const [loanRate, setLoanRate] = useState();
  const [amortization, setAmortization] = useState();

  useEffect(() => {
    getCalculatorSettings().then(res => {
      const sorted = res.sort((a, b) => a.field.localeCompare(b.field));

      setData(sorted);
    });
  }, []);

  useEffect(() => {
    if (selectedLoan) {
      calculate();
    }
  }, [amount, term]);

  const handleOnSelect = option => {
    setSelectedLoan(option);
    setAmount();
    setTerm();
  };

  const PMT = (ir, np, pv, fv = 0) => {
    const presentValueInterstFector = Math.pow(1 + ir, np);
    const pmt =
      (ir * pv * (presentValueInterstFector + fv)) /
      (presentValueInterstFector - 1);
    return pmt;
  };

  const calculate = () => {
    const { loan_rate } = selectedLoan;

    const res = loan_rate.find(x => {
      const [from, to] = x.range;

      if (term >= from && term <= to) {
        return true;
      }

      return false;
    });

    if (term && amount) {
      /** Loan Rate Calculation */
      const rate = (res?.rate * 100).toFixed(2) + '%';
      setLoanRate(rate === 'NaN%' ? undefined : rate);

      /** Amortization Calculation */
      const ir = res?.rate / 12; // Interest Rate
      const np = term * 12; // Number of Payment
      const pv = amount; // Present Value
      const amort = '₱' + PMT(ir, np, pv).toFixed(2);
      setAmortization(amort === '₱NaN' ? undefined : amort);
    } else {
      setLoanRate();
      setAmortization();
    }
  };

  return (
    <div className="loan-details-calc">
      <div className="loan-details-calc__section section-1">
        <h2 className="title">Loan Details and Calculator</h2>
        <p className="desc">
          View all available loan products and use the loan calculator for ease
          of use.
        </p>
      </div>

      <div className="loan-details-calc__grid">
        <div className="loan-details-calc__section section-grid">
          <h2 className="title">Choose a Coop Product</h2>
          <CsSelect
            keyRef="id"
            displayRef="field"
            label="Select Loan"
            options={data}
            onSelect={handleOnSelect}
          />
          <p className="desc">{selectedLoan?.desc}</p>
        </div>

        <div className="mediator">
          <FontAwesomeIcon icon={faArrowRightLong} />
        </div>

        <div className="loan-details-calc__section section-grid">
          <h2 className="title">{selectedLoan?.label || 'Loan'} Calculator</h2>

          <div className="input">
            <label>Loan Amount</label>
            <CsInput
              type="number"
              placeholder="Loan Amount"
              value={amount}
              max={selectedLoan?.max_amount}
              onChange={value => {
                const maxAmount = parseFloat(selectedLoan?.max_amount);
                const newAmount = parseFloat(value);

                if (newAmount <= maxAmount) {
                  setAmount(value);
                }

                if (value < 0) {
                  setAmount(0);
                }

                if (!value) {
                  setAmount();
                }
              }}
            />
            <p>
              Enter desired amount
              {selectedLoan ? ` up to ${selectedLoan?.max_amount_text}` : ''}.
            </p>
          </div>

          <div className="input">
            <label>Term to Pay (Years)</label>
            <CsInput
              type="number"
              placeholder="Term to Pay (Years)"
              value={term}
              max={selectedLoan?.max_term}
              onChange={value => {
                const maxTerm = parseFloat(selectedLoan?.max_term);
                const newTerm = parseFloat(value);

                if (newTerm <= maxTerm) {
                  setTerm(value);
                }

                if (value < 1) {
                  setTerm(1);
                }

                if (!value) {
                  setTerm();
                }
              }}
            />
            <p>
              Enter desired term
              {selectedLoan
                ? ` up to ${selectedLoan?.max_term} years`
                : ' in years'}
              .
            </p>
          </div>

          <div className="input">
            <label>Loan Rate</label>
            <CsInput placeholder="Loan Rate" value={loanRate} readOnly />
            <p>This is calculated based on your term.</p>
          </div>

          <div className="input">
            <label>Monthly Amortization</label>
            <CsInput placeholder="₱0,000.00" value={amortization} readOnly />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanDetailsCalc;
