import './App.css';
import './styles/layout.scss';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate
} from 'react-router-dom';
import Sidebar from './comps/layout/Sidebar';
import Navbar from './comps/layout/Navbar';
import Dashboard from './pages/dashboard/Dashboard';
import Settings from './pages/settings/Settings';
import Products from './pages/products/_index';
import SOA from './pages/soa/_index';
import { useEffect, useState } from 'react';
import ManageProducts from './pages/manage-products/_index';
import ManageMembers from './pages/manage-members/_index';
import Login from './pages/login/Login';
import ForgotPassword from './pages/login/ForgotPassword';
import DownloadSOA from './pages/soa/DownloadSOA';
import Forum from './pages/forum/Forum';
import SOAs from './pages/soas/_index';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import {
  getUser,
  getUserFromSessionStorage,
  logout
} from './core/services/auth';
import { getLatestSOADate, getSOALogs } from './core/services/dashboard';

function Admin() {
  return (
    <Routes>
      <Route path="/manage-soa/*" element={<SOAs />} />
      <Route path="/manage-members/*" element={<ManageMembers />} />
      <Route path="/manage-products/*" element={<ManageProducts />} />
      <Route path="/settings" element={<Settings />} />
    </Routes>
  );
}

function Member() {
  return (
    <Routes>
      <Route path="/products/*" element={<Products />} />
      <Route path="/soa/*" element={<SOA />} />
      <Route path="/settings" element={<Settings />} />
    </Routes>
  );
}

function Layout() {
  const user = getUserFromSessionStorage();
  const [isAdmin] = useState(user?.role === 'admin');
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <div className="main-layout">
      <Sidebar showSidebar={showSidebar} setShowSidebar={show => setShowSidebar(show)} />

      <div className="main">
        <Navbar showSidebar={showSidebar} setShowSidebar={show => setShowSidebar(show)} />

        <div className="content">
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/announcements" element={<Forum />} />
            <Route path="/*" element={isAdmin ? <Admin /> : <Member />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

function Main() {
  const [userLoaded, setUserLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, async user => {
      let expired = false;

      if (!user) {
        navigate('/login');
        return;
      }

      (function checkAccess() {
        const lastLogin = new Date(user.metadata.lastSignInTime).getTime();
        const currentTime = new Date().getTime();
        const diff = Math.round((currentTime - lastLogin) / 60000);
        expired = diff > 9;

        if (expired) {
          logout();
          return;
        }

        setTimeout(checkAccess, 5000);
      })();

      if (expired) {
        logout();
        return;
      }

      const ud = getUserFromSessionStorage();
      if (!ud) {
        const userData = await getUser(user);
        const recentSOA = await getLatestSOADate();
        const soaDates = await getSOALogs();
        sessionStorage.setItem(
          'dsebspcc_user',
          JSON.stringify({
            ...userData,
            soa_dates: soaDates,
            recent_soa_log: recentSOA
          })
        );
      }
      setUserLoaded(true);
      navigate('/dashboard');
    });
  }, []);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      {userLoaded ? (
        <>
          <Route path="/*" element={<Layout />} />
          <Route path="/download-soa" element={<DownloadSOA />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </>
      ) : null}
    </Routes>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  );
}

export default App;
