import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MemberLoans from './MemberLoans';
import MemberSavings from './MemberSavings';

const SOA = () => {
  return (
    <Routes>
      <Route path="/member-savings" element={<MemberSavings />} />
      <Route path="/member-loans" element={<MemberLoans />} />
    </Routes>
  );
};

export default SOA;
