import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LoanApplication from './LoanApplication';
import LoanDetailsCalc from './LoanDetailsCalc';

const Products = () => {
  return (
    <Routes>
      <Route path="/loan-application" element={<LoanApplication />} />
      <Route path="/loan-details-calc" element={<LoanDetailsCalc />} />
    </Routes>
  );
};

export default Products;
