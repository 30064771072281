import './../../styles/pages/upload-soas.scss';
import React, { useState } from 'react';
import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize
} from 'react-papaparse';
import CsButton from '../../comps/CsButton';
import { importToFirestore } from '../../core/services/soa';

const DEFAULT_REMOVE_HOVER_COLOR = '#A01919';
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40
);

const UploadSOAs = () => {
  const { CSVReader } = useCSVReader();
  const [removeHoverColor, setRemoveHoverColor] = useState(
    DEFAULT_REMOVE_HOVER_COLOR
  );

  const [loansSOA, setLoansSOA] = useState();
  const [savingsSOA, setSavingsSOA] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleUploadLoansSOA = () => {
    setIsLoading(true);
    setLoansSOA();

    importToFirestore({ soa: loansSOA, table: 'loans' })
      .then(res => {
        alert(
          `Loans SOAs are now updated! Total data lines: ${res.count}, Failed: ${res.failed.length}`
        );
      })
      .catch(err => {
        alert('Something went wrong.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleUploadSavingsSOA = () => {
    setIsLoading(true);
    setSavingsSOA();

    importToFirestore({ soa: savingsSOA, table: 'balances' })
      .then(res => {
        alert(
          `Savings SOAs are now updated! Total data lines: ${res.count}, Failed: ${res.failed.length}`
        );
      })
      .catch(err => {
        alert('Something went wrong.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="upload-soas">
      <div className="upload-soas__grid">
        <div className="upload-soas__grid--item">
          <CSVReader
            onUploadAccepted={results => {
              const { data } = results;
              const objectify = data.map(rowData => {
                let result = {};
                let colCount = data[0].length;
                let colNames = data[0];

                for (let i = 0; i < colCount; i++) {
                  result[colNames[i]] = rowData[i];
                }

                return result;
              });

              objectify.shift();

              setLoansSOA(objectify);
            }}
          >
            {({
              getRootProps,
              acceptedFile,
              ProgressBar,
              getRemoveFileProps,
              Remove
            }) => (
              <div className="csv-reader" {...getRootProps()}>
                {acceptedFile ? null : setLoansSOA()}
                {acceptedFile ? (
                  <div className="file">
                    <div className="info">
                      <span className="size">
                        {formatFileSize(acceptedFile.size)}
                      </span>
                      <span className="name">{acceptedFile.name}</span>
                    </div>
                    <div className="progress-bar">
                      <ProgressBar />
                    </div>
                    <div
                      className="remove"
                      {...getRemoveFileProps()}
                      onMouseOver={event => {
                        event.preventDefault();
                        setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                      }}
                      onMouseOut={event => {
                        event.preventDefault();
                        setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                      }}
                    >
                      <Remove color={removeHoverColor} />
                    </div>
                  </div>
                ) : (
                  'Click to choose Loans SOA CSV file'
                )}
              </div>
            )}
          </CSVReader>
          {isLoading ? (
            <span>Uploading... Please do not reload the page.</span>
          ) : null}

          {loansSOA && (
            <CsButton label="Upload Loans SOA" onClick={handleUploadLoansSOA} />
          )}
        </div>

        <div className="upload-soas__grid--item">
          <CSVReader
            onUploadAccepted={results => {
              const { data } = results;
              const objectify = data.map(rowData => {
                let result = {};
                let colCount = data[0].length;
                let colNames = data[0];

                for (let i = 0; i < colCount; i++) {
                  result[colNames[i]] = rowData[i];
                }

                return result;
              });

              objectify.shift();

              setSavingsSOA(objectify);
            }}
          >
            {({
              getRootProps,
              acceptedFile,
              ProgressBar,
              getRemoveFileProps,
              Remove
            }) => (
              <div className="csv-reader" {...getRootProps()}>
                {acceptedFile ? null : setSavingsSOA()}
                {acceptedFile ? (
                  <div className="file">
                    <div className="info">
                      <span className="size">
                        {formatFileSize(acceptedFile.size)}
                      </span>
                      <span className="name">{acceptedFile.name}</span>
                    </div>
                    <div className="progress-bar">
                      <ProgressBar />
                    </div>
                    <div
                      className="remove"
                      {...getRemoveFileProps()}
                      onMouseOver={event => {
                        event.preventDefault();
                        setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                      }}
                      onMouseOut={event => {
                        event.preventDefault();
                        setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                      }}
                    >
                      <Remove color={removeHoverColor} />
                    </div>
                  </div>
                ) : (
                  'Click to choose Savings SOA CSV file'
                )}
              </div>
            )}
          </CSVReader>
          {isLoading ? (
            <span>Uploading... Please do not reload the page.</span>
          ) : null}

          {savingsSOA && (
            <CsButton
              label="Upload Savings SOA"
              onClick={handleUploadSavingsSOA}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadSOAs;
