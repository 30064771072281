import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Members from './Members';
import Logs from './Logs';

const ManageMembers = () => {
  return (
    <Routes>
      <Route path="/members" element={<Members />} />
      <Route path="/logs" element={<Logs />} />
    </Routes>
  );
};

export default ManageMembers;
